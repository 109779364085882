let journeyType = null;
let userProfile = null;
let jwtToken = null;

// UTILITY FUNCTIONS
function getCookie(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
}

function maskNumber(number) {
  if (number?.length <= 4) {
    return number;
  }

  const visibleDigits = number?.slice(-4);
  const maskedDigits = number?.slice(0, -4)?.replace(/\d/g, '*');

  return maskedDigits + visibleDigits;
}

// REDIRECT UTILS
const redirect_to_dashboard = () => {
  window.location.href = window._Torus.DASHBOARD_URL;
};

const redirect_to_under_review = () => {
  // TODO: Verify
  const token = getCookie('token');
  window.location.href = `/auth/verify?token=${token}`;
};

const redirect_to_download_app_page = () => {
  const download_app_page_redirection = true;
  if (download_app_page_redirection) {
    window.location.href = `/download-3-in-1-app`;
    return 1;
  }
};

// UI UTILS
function closePopup() {
  const pop_up_div = document.getElementById('onboarding-error');
  if (pop_up_div) pop_up_div.style.display = 'none';
}

function showErrorPopUp(msg, resolvedCallback, rejectedCallback) {
  const pop_up_div = document.getElementById('onboarding-error');
  const errorMsgEl = document.getElementById('hvErrorMsg');
  const closeButtonEl = document.getElementById('popup-close-button');

  const tryAgainButton = document.getElementById('try_again_button');

  errorMsgEl.innerHTML =
    msg || 'There was an error processing your request. Please try again.';
  pop_up_div.style.display = 'block';
  tryAgainButton.addEventListener(
    'click',
    function (event) {
      event.preventDefault();
      closePopup();
      if (resolvedCallback) resolvedCallback();
    },
    { once: true },
  );

  closeButtonEl.addEventListener(
    'click',
    function (event) {
      event.preventDefault();
      closePopup();
      if (rejectedCallback) rejectedCallback();
    },
    { once: true },
  );
}

function loadingSpinner() {
  const spinner = document.createElement('div');
  spinner.classList.add('spinner');

  return {
    show: function (element) {
      element.appendChild(spinner);
    },
    hide: function () {
      spinner.remove();
    },
  };
}

function showModal(modalContentId, fullWidth = false) {
  const modal = document.querySelector('.baseModalPopup');
  const popupLayer = document.querySelector('.popup-layer');
  if (modal) {
    if (modalContentId) {
      modal.classList.add('show');
      popupLayer.classList.add('show');

      if (fullWidth) {
        modal.classList.add('full');
      } else {
        modal.classList.remove('full');
      }

      const contents = modal.children;

      for (let content of contents) {
        if (content.id === modalContentId) {
          content.style.display = 'flex';
          content.classList.add('show');
        } else {
          content.style.display = 'none';
          content.classList.remove('show');
        }
      }
    } else {
      modal.classList.remove('show');
      popupLayer.classList.remove('show');
    }
  }
}

function removeBankModal() {
  const ssfbModule = document.querySelector('.ssfb-modal');
  if (ssfbModule) {
    ssfbModule.parentNode.removeChild(ssfbModule);
    return true;
  }
  return false;
}

// FETCH API FUNCTIONS

async function fetchUserDetails(jwtToken) {
  try {
    const url = `${window._Torus.BFF_BASE_URL}/users/me`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + jwtToken,
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    });
    if (!response.ok) {
      throw new Error('Failed to fetch user details');
    }
    return response.json();
  } catch (error) {
    trigger_sentry_error(
      `There was an error while fetching user details`,
      error.stack,
    );
  }
}

async function fetchUserDetailsUserSVC(jwtToken) {
  try {
    const response = await fetch(
      `${window._Torus.USERSSVC_BASE_URL}/users/api/users/me?populate=*`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + jwtToken,
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      throw new Error('Failed to fetch user details');
    }
    return response.json();
  } catch (error) {
    trigger_sentry_error(
      `There was an error while fetching user details`,
      error.stack,
    );
  }
}

async function fetchUserAccounts(jwtToken, phone) {
  try {
    const url = `${window._Torus.BFF_BASE_URL}/customers/mobile/${phone}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + jwtToken,
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    });
    if (!response.ok) {
      throw new Error('Failed to fetch user details');
    }
    return response.json();
  } catch (error) {
    trigger_sentry_error(
      `There was an error while fetching user details`,
      error.stack,
    );
  }
}

async function fetchOnboarding(jwtToken) {
  try {
    const url = `${window._Torus.BFF_BASE_URL}/onboarding`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + jwtToken,
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    });
    if (!response.ok) {
      throw new Error('Failed to fetch onboarding details');
    }
    return response.json();
  } catch (error) {
    trigger_sentry_error(
      `There was an error while fetching onboarding details`,
      error.stack,
    );
  }
}

async function setUserJourney(journeyType, jwtToken) {
  return fetch(
    `${window._Torus.USERSSVC_BASE_URL}/users/api/users/set-journey`,
    {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + jwtToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ journeyType: journeyType }),
    },
  ).then((res) => {
    if (!res.ok) throw new Error('Failed to fetch');
    else return res;
  });
}

async function fetchGenerateToken(jwtToken) {
  try {
    const url = `${window._Torus.BFF_BASE_URL}/onboarding/generate-token`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + jwtToken,
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    });
    if (!response.ok) {
      throw new Error('Failed to generate token');
    }
    return response.json();
  } catch (error) {
    trigger_sentry_error(
      'There was an error while generating token',
      error.stack,
    );
  }
}

async function onboardOnStart() {
  try {
    const url = `${window._Torus.USERSSVC_BASE_URL}/users/api/onboardings/on-start`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + jwtToken,
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Failed to generate token');
    }
    return response.json();
  } catch (error) {
    trigger_sentry_error(
      'There was an error while generating token',
      error.stack,
    );
  }
}

async function linkAccount(jwtToken, customer, account) {
  const body = JSON.stringify({
    panNumber: customer.panNumber,
    ssfbCid: customer.customerId,
    bankAccountNumber: account.accountId,
    customerNameAsBank: account.acctName,
  });

  try {
    const url = `${window._Torus.USERSSVC_BASE_URL}/users/api/onboardings/modifications/link-b-primary`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + jwtToken,
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body,
    });
    if (!response.ok) {
      throw new Error('Failed to fetch user details');
    }
    return response.json();
  } catch (error) {
    console.log(error);
    // trigger_sentry_error(
    //   'There was an error while linking account',
    //   error.stack,
    // )
  }
}

async function trigger_sentry_error(error_message, error_stack) {
  const csrftoken = getCookie('csrftoken');
  console.log('csrftoken', csrftoken);

  await fetch(`${window.location.origin}/capture-error/`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrftoken,
    },
    body: JSON.stringify({
      error: error_message,
      error_stack: error_stack,
    }),
  });
}

async function checkUserHasAccounts(jwtToken) {
  try {
    const phone = jwtToken ? jwt_decode(jwtToken).phone : null;
    const data = await fetchUserAccounts(jwtToken, phone);
    return data.actDtls.length > 0;
  } catch (error) {
    trigger_sentry_error('Failed to check user accounts', error.stack);
  }
}

async function accountsListScreen(handleNextStep) {
  try {
    showModal('accountsList');
    const modal = document.querySelector('.baseModalPopup');
    modal.classList.remove('bg-orange');

    if (!jwtToken) throw new Error('JWT token is missing');

    const decodedToken = jwt_decode(jwtToken);
    if (!decodedToken || !decodedToken.phone)
      throw new Error('Invalid or missing phone number');

    const phone = decodedToken.phone;
    const container = document.querySelector('.accounts-list');
    const template = document.querySelector('.account-item');
    const phoneNumber = document.getElementById('linked-number');

    container.innerHTML = '';

    const spinner = loadingSpinner();
    spinner.show(container.parentNode);

    let data;
    try {
      data = await fetchUserAccounts(jwtToken, phone);
    } catch (fetchError) {
      console.error('Failed to fetch user accounts:', fetchError);
      showModal();
      showErrorPopUp(
        'Unable to retrieve account details. Please try again.',
        tryAgainLinkAccount,
      );
      spinner.hide();
      return;
    }

    if (
      !data ||
      !Array.isArray(data.actDtls) ||
      !Array.isArray(data.custDtls)
    ) {
      throw new Error('Invalid response structure from fetchUserAccounts');
    }

    phoneNumber.innerHTML = '+91 ' + maskNumber(phone);

    spinner.hide();

    const accounts = data.actDtls.filter((account) => account.group === 'SAV');
    const customer = data.custDtls[0];

    accounts.forEach((account, index) => {
      const clone = template.cloneNode(true);
      const input = clone.querySelector("input[type='radio']");
      const bankName = clone.querySelector('.bank-name');
      const accountNumber = clone.querySelector('.account-number');
      const accountType = clone.querySelector('.account-type');
      const accountDetails = clone.querySelector('.account-details ul');

      if (
        !input ||
        !bankName ||
        !accountNumber ||
        !accountType ||
        !accountDetails
      ) {
        console.warn(
          `Skipping account at index ${index} due to missing elements`,
        );
        return;
      }

      input.id = `account${index + 1}`;
      input.name = 'account';
      input.value = account.accountId;
      clone.setAttribute('for', input.id);

      bankName.textContent = 'Suryoday Small Finance Bank';
      accountNumber.textContent = account.accountId || 'N/A';
      accountType.textContent = 'Savings Account';

      accountDetails.innerHTML = `
        <li><strong>Account Name:</strong> ${account.acctName || 'N/A'}</li>
        <li><strong>IFSC:</strong> ${account.ifsc || '1234'}</li>
        <li><strong>Customer ID:</strong> ${customer.customerId || 'N/A'}</li>
      `;

      container.appendChild(clone);
    });

    const linkButton = document.querySelector('#accountsListSubmit');

    const radios = document.querySelectorAll('input[name="account"]');

    radios.forEach((radio) => {
      radio.addEventListener('change', () => {
        linkButton.disabled = false;
      });
    });

    linkButton.addEventListener(
      'click',
      async () => {
        const selectedAccount = document.querySelector(
          'input[name="account"]:checked',
        )?.value;
        const accountInfo = accounts.find(
          (account) => account.accountId === selectedAccount,
        );

        if (accountInfo) {
          const isLinked = await linkAccount(jwtToken, customer, accountInfo);
          if (isLinked) {
            if (handleNextStep) handleNextStep();
          } else {
            showErrorPopUp('Failed to link the account', () =>
              accountsListScreen(() => successScreen(redirect_to_dashboard)),
            );
          }
        }
      },
      { once: true },
    );
  } catch (error) {
    console.error('Error in onAcceptTerms:', error);
  }
}

function consentScreen(handleNextStep) {
  const modal = document.querySelector('.baseModalPopup');
  modal.classList.add('bg-orange');

  const termsButton = document.getElementById('3-in-1-consent');

  termsButton.addEventListener(
    'click',
    () => {
      if (handleNextStep) handleNextStep();
    },
    { once: true },
  );

  const consent1 = document.getElementById('3-in-1-consent-1');
  const consent2 = document.getElementById('3-in-1-consent-2');

  consent1.addEventListener('change', function (event) {
    const consent2 = document.getElementById('3-in-1-consent-2');
    termsButton.disabled = !consent1.checked || !consent2.checked;
  });

  consent2.addEventListener('change', function (event) {
    const consent1 = document.getElementById('3-in-1-consent-1');
    termsButton.disabled = !consent1.checked || !consent2.checked;
  });

  showModal('3-in-1');
}

function successScreen(handleNextStep) {
  showModal('bank-success');
  setTimeout(() => {
    if (handleNextStep) handleNextStep();
  }, 5000);
}

function tryAgainOnboarding() {
  startHVJourney(jwtToken, journeyType, userProfile);
}

const handleUserAccountFlow = async () => {
  const hasAccounts = await checkUserHasAccounts(jwtToken);

  if (hasAccounts) {
    accountsListScreen(() => successScreen(redirect_to_dashboard));
  } else {
    window.addEventListener('message', async (messageEvent) => {
      if (!jwtToken) return;

      if (messageEvent.data) {
        if (messageEvent.data?.eventName === 'onDone') {
          redirect_to_dashboard();
        }
      }
    });
    startBankJourney(userProfile);
  }
};

function tryAgainLinkAccount() {
  if (redirect_to_download_app_page()) return;
  consentScreen(handleUserAccountFlow);
}

function render_success_page() {
  window.location.href = '/onboarding/status';
}

async function startOnBoarding(jwtToken, journeyType = '2in1', userProfile) {
  const user = userProfile?.data?.user;

  const overlayClassName = 'onboarding-redirect';
  try {
    document.getElementById(overlayClassName).style.display = 'block';

    await onboardOnStart();

    const userDetailsResponse = await fetchUserDetails(jwtToken);

    const email = userDetailsResponse.data.user.email;
    const mobileNumber = userDetailsResponse.data.user.phone;

    const onboardingResponse = await fetchOnboarding(jwtToken);
    const transactionId = onboardingResponse.data.hypervergeTransactionID;
    const hypervergeUUID = onboardingResponse.data.hypervergeUUID;

    const generatedToken = await fetchGenerateToken(jwtToken);
    const accessToken = generatedToken.token;
    const workflowId =
      journeyType === '3in1'
        ? window._Torus.HV_3IN1_WOKRFLOW_ID
        : window._Torus.HV_2IN1_WOKRFLOW_ID;
    const appId = window._Torus.HV_APP_ID;
    try {
      window.HyperKYCModule.prefetch(appId, workflowId);
    } catch (error) {
      console.error('Error in prefetch:', error);
    }
    const hyperKycConfig = new window.HyperKycConfig(
      accessToken,
      workflowId,
      transactionId,
    );

    let customInputs = null;

    if (journeyType === '2in1') {
      customInputs = {
        leadId: transactionId,
        mobileNumber: mobileNumber || 'NA',
        email: email || 'NA',
      };
    } else if (journeyType === '3in1') {
      customInputs = {
        leadId: user?.onboarding?.hypervergeTransactionID || 'NA',
        email: user?.email || 'NA',
        fullName: user?.onboarding?.nameAsBank || 'NA',
        firstName: user?.onboarding?.firstName || 'NA',
        lastName: user?.onboarding?.lastName || 'NA',
        gender:
          user?.onboarding?.genderAsDigilockerAadhaar === 'M'
            ? 'Male'
            : user?.onboarding?.genderAsDigilockerAadhaar === 'F'
              ? 'Female'
              : 'Others',
        incomeRange: user?.onboarding?.grossAnnualIncome || 'NA',
        occupation: user?.onboarding?.occupation || 'NA',
        maritalStatus: user?.onboarding?.maritalStatus || 'NA',
        panNumber: user?.onboarding?.panNumber || 'NA',
        nomineeName: user?.onboarding?.nominee || 'NA',
        pepDeclaration: user?.onboarding?.politicallyExposedPerson
          ? 'YES'
          : 'NO',
        nomineeShare: user?.onboarding?.nomineeShare || 'NA',
        // fatherSpouseName!.isNotEmpty

        nomineeRelation: user?.onboarding.nominieeOneRelation || 'NA',
        nomineeDob: user?.onboarding.nominieeOneDob || 'NA',
        nomineeAddress1: user?.onboarding?.nominieeOneAddressLine1 || 'NA',
        nomineeAddress2: user?.onboarding?.nominieeOneAddressLine2 || 'NA',
        nomineeAddress3: user?.onboarding?.nominieeOneAddressLine3 || 'NA',
        nomineePincode: user?.onboarding?.nominieeOnePinCode || 'NA',
        nomineeGuardianName: user?.onboarding.nominieeOneGuardianName || 'NA',
        nomineeGuardianRelation:
          user?.onboarding.nominieeOneGuardianRelation || 'NA',
        nomineeGuardianDob: user?.onboarding.nominieeOneGuardianDOB || 'NA',
        mobileNumber: user?.phone || 'NA',
        fatherSpouseName: user?.onboarding.fatherName || 'NA',
        bankAccountNumber: user?.onboarding.bankAccountNumber || 'NA',
        ifscCode: user?.onboarding?.bankIFSCNumber || 'NA',
        micrCode: user?.onboarding?.micrCode || 'NA',
        bankName: user?.onboarding?.bankName || 'NA',
        bankAddress: user?.onboarding?.bankAddress || 'NA',
        bankBranch: user?.onboarding?.bankBranch || 'NA',
      };
    }

    hyperKycConfig.setUniqueId(hypervergeUUID);
    hyperKycConfig.setInputs(customInputs);
    hyperKycConfig.setCustomFontStylesheet(window._Torus.HV_FONT_STYLESHEET);

    const handler = (HyperKycResult) => {
      const messages = {
        digilocker_verification_failed:
          'Oops! We encountered a DigiLocker system error. Please try again later',
        pan_verification_failed:
          'Oops! PAN Verification failed. Please try again later',
        db_ocr_name_match_failure:
          'Oops! Name mismatch detected. Please verify details and try again',
        pan_db_check_verification_failed:
          'Oops! PAN verification failed. Please verify the details and try again',
        pan_dedupe_verification_failed: 'Oops! PAN already exits',
        aml_verification_failed:
          'OOps! PAN is barred. Please contact support for assistance',
        bank_account_verification_failed:
          'Oops! Bank account verification failed. Please try again later',
        bank_name_pan_name_match_failed:
          'Oops! Bank Proof name mismatch detected. Please verify the details and try again',
        selfie_verification_failed:
          'Oops! Selfie verification failed. Please try again',
        facematch_verification_failed:
          'Oops! Facematch failed. Please try again',
        signature_verification_failed:
          'Oops! signature verification failed. Please try again',
        esign_verification_failed:
          'Oops! Esigner name mismatch detected. Please verify the details and try again',
        pan_ocr_name_mismatch: 'name mismatch between PAN OVD and Aadhaar',
        name_split_api_failed:
          'Name split API returned empty values or failed to execute',
        gov_nsdl_server_down:
          'NSDL servers are down for validating the PAN details',
      };
      document.getElementById(overlayClassName).style.display = 'none';

      switch (HyperKycResult.status) {
        case 'user_cancelled':
          showErrorPopUp(
            'Your kyc is pending for completion',
            tryAgainOnboarding,
          );
          break;
        case 'error':
          showErrorPopUp('Something went wrong...', tryAgainOnboarding);
          trigger_sentry_error(
            `Hyperverge: ${HyperKycResult.status}`,
            HyperKycResult,
          );
          break;
        case 'auto_approved':
          render_success_page();
          break;
        case 'auto_declined':
          showErrorPopUp(
            messages?.[HyperKycResult.errorType] ||
              HyperKycResult.details.errorMessage,
            tryAgainOnboarding,
          );
          trigger_sentry_error(
            `Hyperverge: ${HyperKycResult.status}`,
            HyperKycResult,
          );
          break;
        case 'needs_review':
          showErrorPopUp(
            messages?.[HyperKycResult.errorType] ||
              HyperKycResult.details.errorMessage,
            tryAgainOnboarding,
          );
          trigger_sentry_error(
            `Hyperverge: ${HyperKycResult.status}`,
            HyperKycResult,
          );
          break;
        default:
          showErrorPopUp(
            messages[HyperKycResult.errorType],
            tryAgainOnboarding,
          );
          trigger_sentry_error('Hyperverge : Unknown status', HyperKycResult);
      }
    };

    window.HyperKYCModule.launch(hyperKycConfig, handler);
  } catch (error) {
    document.getElementById(overlayClassName).style.display = 'none';
    showErrorPopUp('Something went wrong...', tryAgainOnboarding);
    trigger_sentry_error('There was an error in startOnBoarding', error.stack);
  }
}

const startHVJourney = (jwtToken, journeyType = '2in1', userProfile) => {
  const head = document.getElementsByTagName('head')[0];

  if (!document.getElementById('hyperverge-sdk')) {
    const js = document.createElement('script');
    js.type = 'text/javascript';
    js.onerror = () => {
      console.log('HV load Failed');
    };
    js.onload = () => {
      console.log('HV loaded');
      window.history.replaceState({}, document.title, window.location.pathname);
      removeBankModal();
      startOnBoarding(jwtToken, journeyType, userProfile);
    };
    js.id = 'hyperverge-sdk';
    js.src =
      journeyType === '3in1'
        ? window._Torus.HV_3IN1_SDK_PATH
        : window._Torus.HV_2IN1_SDK_PATH;

    head.appendChild(js);
  } else {
    removeBankModal();
    startOnBoarding(jwtToken, journeyType, userProfile);
  }
};

const startBankJourney = (userProfile) => {
  const body = document.getElementsByTagName('body')[0];
  const iframe = document.createElement('iframe');

  iframe.allow = 'geolocation';
  iframe.onerror = () => {
    console.log('SB load Failed');
  };
  iframe.onload = () => {
    showModal();
    console.log('SB loaded');
  };

  // iframe.src = window._Torus.TDPL_URL + '/onboard-bank';
  iframe.src =
    'https://stage.torusdigital.com' +
    `/onboard-bank/?phone=${userProfile?.data?.user?.phone}&email=${userProfile?.data?.user?.email}&uid=${userProfile?.data?.user?.uniqueCientCode} `;

  const container = document.createElement('div');
  container.className = 'ssfb-modal';
  container.appendChild(iframe);

  body.classList.add('no-scroll');
  body.appendChild(container);
};

const setUserJourneyType = (jwtToken) => {
  return new Promise((resolve, reject) => {
    let submitButton = document.getElementById('onboard_choice_submit');
    if (!submitButton) {
      reject(new Error('Unsolicited Invocation - Set Journey'));
      return;
    }
    submitButton.disabled = true;
    journeyType = document.querySelector(
      'input[name="onboard_choice"]:checked',
    ).value;
    if (journeyType) {
      setUserJourney(journeyType, jwtToken)
        .then((data) => {
          resolve(data);
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        })
        .finally(() => {
          submitButton.disabled = false;
        });
    } else {
      console.log('Journey Type Not Specified');
      reject(new Error('Journey Type Not Specified'));
    }
  });
};

const onNewUserConsentGiven = async (userProfile) => {
  const hasAccounts = await checkUserHasAccounts(jwtToken);

  if (hasAccounts) {
    accountsListScreen(() => {
      fetch(
        `${window._Torus.USERSSVC_BASE_URL}/users/api/users/me?populate=*`,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + jwtToken,
            'Content-Type': 'application/json',
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          userProfile = data;
          startHVJourney(jwtToken, '3in1', userProfile);
        });
    });
  } else {
    window.addEventListener('message', async (messageEvent) => {
      if (!jwtToken) return;

      if (messageEvent.data) {
        if (messageEvent.data?.eventName === 'onDone') {
          fetch(
            `${window._Torus.USERSSVC_BASE_URL}/users/api/users/me?populate=*`,
            {
              method: 'GET',
              headers: {
                Authorization: 'Bearer ' + jwtToken,
                'Content-Type': 'application/json',
              },
            },
          )
            .then((response) => response.json())
            .then((data) => {
              userProfile = data;
              startHVJourney(jwtToken, '3in1', userProfile);
            });
        }
      }
    });
    startBankJourney(userProfile);
  }
};

document.addEventListener('DOMContentLoaded', () => {
  const handle3in1 = (jwtToken, userProfile) => {
    if (userProfile?.data?.user?.ssfb_cid) {
      handle2in1(jwtToken, '3in1', userProfile);
    } else {
      if (redirect_to_download_app_page()) return;
      consentScreen(() => onNewUserConsentGiven(userProfile));
    }
  };

  const handle2in1 = (jwtToken, journeyType, userProfile) => {
    const onboardingResponse = userProfile?.data?.user?.onboarding;

    // const status = onboardingResponse?.hypervergeApplicationStatus?.trim();
    if (
      ['approve', 'auto_approved'].includes(
        onboardingResponse?.hypervergeApplicationStatus,
      )
    ) {
      // Show Dashboard
      redirect_to_dashboard();
    } else if (
      ['needs_review', 'manualReview'].includes(
        onboardingResponse?.hypervergeApplicationStatus,
      )
    ) {
      // Show In Process
      redirect_to_under_review();
    } else if (
      ['decline', 'auto_declined'].includes(
        onboardingResponse?.hypervergeApplicationStatus,
      )
    ) {
      // onboardingResponse.failReason
      showErrorPopUp(
        `You're application was declined. ${onboardingResponse.failReason} Try again.`,
        tryAgainOnboarding,
      );
    } else if (
      ['cancel', 'user_cancelled', 'error'].includes(
        onboardingResponse?.hypervergeApplicationStatus,
      )
    ) {
      // Invoke HV - Resume Journey
      startHVJourney(jwtToken, journeyType, userProfile);
    } else if (
      [null, 'started'].includes(
        onboardingResponse?.hypervergeApplicationStatus || null,
      )
    ) {
      // Invoke HV
      startHVJourney(jwtToken, journeyType, userProfile);
    } else {
      trigger_sentry_error(
        `Unkonw Hyperverge Application Status: ${onboardingResponse?.hypervergeApplicationStatus || 'UNDEFINED'}`,
        new Error('Unkonw Hyperverge Application Status', {
          cause: onboardingResponse,
        }),
      );
    }
  };

  const urlparams = new URLSearchParams(window.location.search);
  const ctx = urlparams.get('ctx');
  jwtToken = getCookie('llt');

  if (jwtToken) {
    if (ctx === 'onboard' || ctx === 'hv') {
      fetch(
        `${window._Torus.USERSSVC_BASE_URL}/users/api/users/me?populate=*`,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + jwtToken,
            'Content-Type': 'application/json',
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          let onboardingResponse = data?.data?.user?.onboarding;
          journeyType = data.data?.user?.journeyType;
          userProfile = data;
          if (onboardingResponse?.activated) {
            // redirect to Dashboard
            redirect_to_dashboard();
            // TODO: Handle Offline user?
          } else if (ctx == 'hv' && (!journeyType || journeyType == '3in1')) {
            setUserJourney('2in1', jwtToken)
              .then((data) => {
                window.location.reload();
              })
              .catch((e) => {
                console.log(e);
              });
          } else if (['2in1', '3in1'].includes(journeyType)) {
            if (journeyType === '3in1') {
              handle3in1(jwtToken, userProfile);
            } else if (journeyType === '2in1') {
              handle2in1(jwtToken, journeyType, userProfile);
            }
          } else {
            let submitButton = document.getElementById('onboard_choice_submit');
            submitButton.addEventListener('click', () =>
              setUserJourneyType(jwtToken),
            );
            document.body.classList.add('no-scroll');
            showModal('journey', true);
          }
        })
        .catch((error) => {
          console.error('Error validating user Token', error);
        });
    } else if (ctx === 'link-account') {
      if (redirect_to_download_app_page()) return;
      consentScreen(async () => {
        const hasAccounts = await checkUserHasAccounts(jwtToken);

        if (hasAccounts) {
          accountsListScreen(() => successScreen(redirect_to_dashboard));
        } else {
          if (redirect_to_download_app_page()) return;
          window.addEventListener('message', async (messageEvent) => {
            if (!jwtToken) return;

            if (messageEvent.data) {
              if (messageEvent.data?.eventName === 'onDone') {
                removeBankModal();
                successScreen(() => successScreen(redirect_to_dashboard));
              }
            }
          });
          startBankJourney(userProfile);
        }
      });
    }
  }
});

// keeping if required

// async function handleAccountLinking(){
//   try{
//     const hasAccounts = await checkUserHasAccounts(jwtToken);
//     if(hasAccounts){
//       openAccountConfirmation();
//     }
//     else{
//       showConsentScreen(redirect_to_dashboard);
//     }
//   }
//   catch(error){
//     showErrorPopUp("Failed to link account. Please try again.", tryAgainLinkAccount);
//   }
// }

// function openAccountConfirmation(){
//   const modal = document.querySelector('.baseModalPopup')

//   modal.classList.remove('bg-orange')

//   const acceptTerms = document.getElementById('consent-accpet-button');

//   acceptTerms.addEventListener('click',() => {
//     showAccountsList(redirect_to_dashboard)

//   },{once:true});

//   const consent1 = document.getElementById('fetch-bank-details-consent-1');
//   const consent2 = document.getElementById('fetch-bank-details-consent-2');

//   consent1.addEventListener('change', function(event){
//     const consent2 = document.getElementById('fetch-bank-details-consent-2');
//     acceptTerms.disabled = !consent1.checked || !consent2.checked;
//   })

//   consent2.addEventListener('change', function(event){
//     const consent1 = document.getElementById('fetch-bank-details-consent-1');
//     acceptTerms.disabled = !consent1.checked || !consent2.checked;
//   })

//   showModal("account-confirmation")
// }
